#games-contact {
    position: relative;
    background-image: url('../../../../assets/images/games/home-bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.games-left-content {
    position: relative;
    z-index: 2;
}

.games-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(230, 126, 34, 0.7), rgba(78, 44, 6, 0.7));
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
}
