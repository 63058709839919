.labs-services-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
    background: #212529;
}
  
.labs-services-container .labs-service-box {
    position: relative;
    width: 320px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 30px;
    transition: 0.5s;
  }
  
  .labs-services-container .labs-service-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    text-decoration: none;
    background: linear-gradient(315deg, #3498db, #2c3e50);
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
}
  
.labs-services-container .labs-service-box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50;
    width: 50%;
    height: 100%;
    background: linear-gradient(315deg, #2c3e50, #3498db);
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
}
  
.labs-services-container .labs-service-box:hover:before,
.labs-services-container .labs-service-box:hover:after {
    transform: skewX(0deg);
    left: 20px;
    width: calc(100% - 90px);
}
  
.labs-services-container .labs-service-box span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
}
  
.labs-services-container .labs-service-box span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.1s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
  
.labs-services-container .labs-service-box:hover span::before {
    top: -50px;
    left: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
}
  
.labs-services-container .labs-service-box span::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    animation-delay: -1s;
}
  
.labs-services-container .labs-service-box:hover span:after {
    bottom: -50px;
    right: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
}
  
@keyframes animate {
    0%, 100% {
      transform: translateY(10px);
    }
  
    50% {
      transform: translate(-10px);
    }
}
  
.labs-services-container .labs-service-box .labs-service-content {
    position: relative;
    left: 0;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1;
    transform: 0.5s;
    color: #fff;
}
  
.labs-services-container .labs-service-box:hover .labs-service-content {
    left: -25px;
    padding: 60px 40px;
}
  
.labs-services-container .labs-service-box .labs-service-content h2 {
    font-size: 2em;
    color: #fff;
    margin-bottom: 10px;
}
  
.labs-services-container .labs-service-box .labs-service-content p {
    font-size: 1.1em;
    margin-bottom: 10px;
    line-height: 1.4em;
}
  
.labs-services-container .labs-service-box .labs-service-content a {
    display: inline-block;
    font-size: 1.1em;
    color: #111;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    margin-top: 5px;
}
  
.labs-services-container .labs-service-box .labs-service-content a:hover {
    background: #ffcf4d;
    border: 1px solid rgba(255, 0, 88, 0.4);
    box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
