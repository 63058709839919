/* style.css */

#clients {
    color: #fff;
}

.labs-client-card {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    width: 100%; /* Define a largura do card */
    max-width: 300px; /* Define a largura máxima do card */
    margin: 0 auto; /* Centraliza o card */
    margin-bottom: 20px; /* Espaçamento inferior entre os cards */
}
  
.labs-client-card:hover {
    transform: scale(1.1);
}
  
.labs-client-content {
    position: relative;
    left: 0;
    padding: 20px 40px;
    background: rgba(44, 62, 80, 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1;
    transform: 0.5s;
    color: #fff;
}
  
.labs-client-content img {
    width: 100%; /* Garante que a imagem ocupa 100% da largura do contêiner */
    max-height: 200px; /* Define uma altura máxima para a imagem */
    padding: 10px; /* Faz com que a imagem mantenha suas proporções e cubra totalmente o contêiner */
    border-radius: 8px; /* Adiciona borda arredondada à imagem */
}
