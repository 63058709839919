.games-social-icons {
    display: inline-flex;
    align-items: center;
}

.games-social-icon {
    font-size: 1.5em;
    margin-right: 10px;
    color: #fff;
}

.games-social-icon:hover {
    color: orange;
}
