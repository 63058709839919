.games-platform-btn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .games-platform-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .games-platform-btn svg {
    color: #fff;
    font-size: 2em;
  }

  .games-platform-btn.selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px) !important;
  }

  .games-portfolio-card {
    padding-top: 20px;
    width: 90%;
    max-width: 375px;
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    /* cursor: pointer; */
    z-index: 1;
  }
  
  .games-portfolio-card:hover {
    transform: scale(1.05);
    /* box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1); */
  }
  
  .games-portfolio-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  
  .imgBx {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .imgBx img {
    max-width: 220px;
    max-height: 220px;
    border-radius: 10px;
  }
  
  .contentBx {
    flex: 1;
    padding: 20px;
    text-align: center;
  }
  
  .contentBx h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #fff;
  }
  
  .contentBx p {
    color: #ccc;
    font-size: 1em;
    line-height: 1.5;
  }
  
  .download-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #e67e22;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .download-btn:hover {
    background-color: #4e2c06;
  }
  
  