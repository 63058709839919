.bg-with-overlay {
    position: relative;
    background-image: url('../../../../assets/images/labs/home-bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(52, 152, 219, 0.7), rgba(44, 62, 80, 0.7));
}

.labs-hero-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    perspective: 1000px;
    height: 100%;
}

.labs-hero-image img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    object-fit: contain;
    transform: rotateX(10deg);
    transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.labs-hero-image:hover img {
    transform: rotateX(0deg);
}

@media (max-width: 767px) {
    .labs-hero-image {
        height: auto;
    }
}
