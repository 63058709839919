.home-bg-with-overlay {
    position: relative;
    background-image: url('../../../../assets/images/home/home-bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.home-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(4, 137, 226, 0.5), rgba(200, 93, 0, 0.5));
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
}

.home-hero-image {
    position: relative;
    overflow: hidden;
    max-width: 175px;
    margin: auto;
}

.home-hero-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
