#labs-contact {
    position: relative;
    background-image: url('../../../../assets/images/labs/home-bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.labs-left-content {
    position: relative;
    z-index: 2;
}

.labs-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(52, 152, 219, 0.7), rgba(44, 62, 80, 0.7));
}
