.path-title {
  margin-top: 50px;
  padding: 0px 20px;
  color: #fff;
  text-align: center;
}

.home-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  background: #212529;
}

.home-cards-container .home-card-box {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
  cursor: pointer;
}

.home-cards-container .home-card-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: linear-gradient(315deg, #e67e22, #4e2c06);
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.home-cards-container .home-card-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: linear-gradient(315deg, #4e2c06, #e67e22);
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.home-cards-container .home-card-box.second-card::before {
  background: linear-gradient(315deg, #3498db, #2c3e50);
}

.home-cards-container .home-card-box.second-card::after {
  background: linear-gradient(315deg, #2c3e50, #3498db);
}

.home-cards-container .home-card-box:hover:before,
.home-cards-container .home-card-box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.home-cards-container .home-card-box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.home-cards-container .home-card-box span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.home-cards-container .home-card-box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.home-cards-container .home-card-box span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.home-cards-container .home-card-box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%, 100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.home-cards-container .home-card-box .home-card-content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-cards-container .home-card-box:hover .home-card-content {
  left: -25px;
  padding: 60px 40px;
}

.home-cards-container .home-card-box .home-card-content .home-card-icon {
  font-size: 3em;
  margin: 0 auto;
  display: block;
}


.home-cards-container .home-card-box .home-card-content h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.home-cards-container .home-card-box .home-card-content p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
  text-align: center;
}
