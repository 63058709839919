.social-icons {
    display: inline-flex;
    align-items: center;
}

.icon {
    font-size: 1.5em;
    margin-right: 10px;
    color: #fff;
}

.icon:hover {
    color: #1170ff;
}
