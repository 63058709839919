.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.navbar-brand {
  margin-left: 10px;
  margin-right: auto;
}
  
.navbar-nav {
  margin-left: auto;
}

.navbar-nav .nav-item {
  margin-left: 1rem;
}
  
@media (max-width: 768px) {
    .navbar-nav {
      display: none;
      text-align: center;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #2b2f32;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  
    .navbar-nav .nav-item {
      display: block;
    }
  
    .navbar-toggler {
      margin-top: 0.5rem;
      margin-right: 1rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  
    .navbar-toggler:focus {
      box-shadow: none;
    }
}
  